import { Navigate, RouteObject } from 'react-router'
import { useRoutes } from 'react-router-dom'
import { PanelLayout } from '@/layouts'
import { Dashboard, Financial, Login, Register, Server, Service } from '@/pages'

const routes: () => RouteObject[] = () => [
  {
    path: '/user',
    children: [
      {
        path: '/user/login',
        element: <Login />
      },
      {
        path: '/user/register',
        element: <Register />
      }
    ]
  },
  {
    path: '/panel',
    element: <PanelLayout />,
    children: [
      {
        path: '/panel',
        element: <Dashboard />
      },
      {
        path: '/panel/server/:type',
        element: <Server />
      },
      {
        path: '/panel/services/:id',
        element: <Service />
      },
      {
        path: '/panel/financial/:tab',
        element: <Financial />
      }
    ]
  },
  {
    path: '*',
    element: <Navigate to="/panel" />
  }
]

const Routes = () => useRoutes(routes())

export default Routes
