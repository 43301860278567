import { FC } from 'react'
import moment from 'jalali-moment'
import { Tag } from 'antd'
import { List, ListColumn } from '@/components'
import { Transaction } from '@/api'

interface TransactionsProps {
  data: Transaction[]
}

const columns: ListColumn<Transaction>[] = [
  {
    title: 'نوع',
    dataIndex: 'type',
    render: (item: Transaction) => <>{item.type === 'add' ? 'افزایش اعتبار' : 'کاهش اعتبار'}</>,
    width: 100
  },
  {
    title: 'زمان',
    dataIndex: 'createdAt',
    className: 'dir-ltr',
    render: (item: Transaction) => <>{moment(item.createdAt).format('jYYYY-jMM-jDD HH:mm')}</>,
    width: 100
  },
  {
    title: 'مبلغ',
    dataIndex: 'credit',
    render: (item: Transaction) => (
      <div className={`${item.type === 'add' ? 'bg-blue-500' : 'bg-red-600'} text-white text-center px-3 py-1 rounded-md`}>{item.creditString}</div>
    ),
    width: 150
  },
  {
    title: 'وضعیت',
    dataIndex: 'status',
    render: (item: Transaction) => (
      <Tag color={item.status === 'success' ? 'green' : 'red'}>{item.status === 'success' ? 'موفق' : 'نا موفق'}</Tag>
    ),
    width: 100
  }
]

export const Transactions: FC<TransactionsProps> = ({ data }) => (
  <List<Transaction> columns={columns} data={data} />
)
