import { useMutation, useQuery, useQueryClient } from 'react-query'
import { strapi, StrapiResponse } from '@/api/strapi'
import { Service } from '@/api/model/service'
import { ActionService, PowerService } from '@/api/model/api'

export const useServices = () =>
  useQuery<StrapiResponse<Service[]>>('services', () =>
    strapi.request('GET', '/services').then((resp: any) =>
      Promise.resolve({
        data: resp.data.map((s: any) => new Service(s)),
        meta: resp.meta
      })
    )
  )

export const useService = (id?: string) =>
  useQuery<StrapiResponse<Service>>(
    ['services', id],
    () =>
      strapi.request('GET', `/services/${id}`).then((resp: any) =>
        Promise.resolve({
          data: new Service(resp),
          meta: resp.meta
        })
      ),
    {
      enabled: !!id
    }
  )

export const useImages = (id?: string) =>
  useQuery<any>(['images', id], () => strapi.request('GET', `/services/${id}/images`), {
    enabled: !!id
  })

export const usePowerService = () => {
  const queryClient = useQueryClient()
  return useMutation<StrapiResponse<unknown>, unknown, PowerService>(
    'power-service',
    (data) => strapi.request('POST', '/services/power', { data }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('services')
      }
    }
  )
}

export const useEnableRescue = () => {
  const queryClient = useQueryClient()
  return useMutation<StrapiResponse<unknown>, unknown, ActionService>(
    'rescue-service',
    (data) => strapi.request('POST', '/services/rescue', { data }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('services')
      }
    }
  )
}

export const useRebuild = () => {
  const queryClient = useQueryClient()
  return useMutation<any, any, any>(
    'rebuild-service',
    ({ id, data }: any) => strapi.request('POST', `/services/${id}/rebuild`, { data }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('services')
      }
    }
  )
}

export const useIpAction = () => {
  const queryClient = useQueryClient()
  return useMutation<any, any, any>(
    'rebuild-service',
    (data: any) =>
      strapi.request('POST', `/services/ip/action`, {
        data
      }),
    {
      onSuccess: () => {
        setTimeout(() => {
          queryClient.invalidateQueries('primary-ips')
          queryClient.invalidateQueries('services')
        }, 2000)
      }
    }
  )
}

export const usePrimaryIps = (service: any) =>
  useQuery<any, any, any>('primary-ips', () =>
    strapi.request('get', `/services/${service}/ip`, {
      params: {
        pageSize: 100
      }
    })
  )

export const useCreatePrimaryIp = () => {
  const queryClient = useQueryClient()
  return useMutation<any, any, any>(
    'create-new-ip',
    (data: any) =>
      strapi.request('POST', `/services/ip`, {
        data
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('primary-ips')
      }
    }
  )
}

export const useDeletePrimaryIp = () => {
  const queryClient = useQueryClient()
  return useMutation<any, any, any>(
    'delete-new-ip',
    (data: any) =>
      strapi.request('POST', `/services/ip/delete`, {
        data
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('primary-ips')
      }
    }
  )
}
