export interface Server {
  id?: number
  name: string
  price: number
  cpu: string
  ram: string
  hard: string
  traffic: string
  featured: boolean
}

export class Server {
  priceString: string

  constructor(server: Server) {
    this.id = server?.id
    this.name = server?.name
    this.price = server?.price
    this.priceString = `${new Intl.NumberFormat('fa-IR', { maximumSignificantDigits: 3 }).format(server?.price)} تومان`
    this.cpu = server?.cpu
    this.ram = server?.ram
    this.hard = server?.hard
    this.traffic = server?.traffic
    this.featured = server?.featured
  }
}