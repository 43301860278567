import { FC, ReactNode } from 'react'
import { Col, PageHeader, Row } from 'antd'

interface PageLayoutProps {
  title: string | ReactNode
  extra?: ReactNode
}

const PanelPageLayout: FC<PageLayoutProps> = (props) => {
  const { title, extra, children } = props

  return (
    <div className="px-8 py-5">
      <Row className="mb-3">
        <Col span={24}>
          <PageHeader
            className="px-0"
            title={title}
            extra={extra}
          />
        </Col>
      </Row>
      <div className="page-content">{children}</div>
    </div>
  )
}

export default PanelPageLayout
