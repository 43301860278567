import { Service } from '@/api'

export type StrapiUser = Record<string, any> | null

export interface User {
  name: string
  username: string
  email: string
  mobile: string
  credit: number
  services: Service[]
  init?: boolean
}

export class User {
  loggedIn: boolean

  creditString = "0 تومان"

  constructor(user?: StrapiUser) {
    if (!user) {
      this.loggedIn = false
      return
    }

    this.loggedIn = true
    this.name = user?.name
    this.username = user?.username
    this.email = user?.email
    this.mobile = user?.mobile
    this.credit = user?.credit || 0
    this.creditString = `${new Intl.NumberFormat('fa-IR', { maximumSignificantDigits: 3 }).format(user?.credit)} تومان`

    this.services = user?.services?.map((s: any) => new Service(s)) || []
  }

  get servicesCount() {
    if (this.services) return this.services?.length

    return 0
  }
}
