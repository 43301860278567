import moment from 'moment'
import { Server } from '@/api'

export type hetznerStatus = 'running' | 'starting' | 'stopping' | 'off'

export interface Service {
  id: number
  label: string
  ip: string
  location: string
  createdAt: string
  server: Server
  hetzner?: {
    id?: number
    status: hetznerStatus
    created: string
    datacenter: {
      name: string
      location: {
        id: number
        city: string
      }
    }
    public_net: {
      ipv4?: {
        ip: string
        id: number
      }
    }
  }
}

export class Service {
  constructor(service: Service) {
    this.id = service?.id
    this.label = service?.label
    this.ip = service?.ip
    this.location = service?.location
    this.createdAt = service?.createdAt
    this.server = new Server(service?.server)
    this.hetzner = service?.hetzner
  }

  get passedTime() {
    const duration = moment.duration(moment().valueOf() - moment(this.createdAt).valueOf())

    const year = parseInt(duration.asYears().toString())
    const month = parseInt(duration.asMonths().toString())
    const day = parseInt(duration.asDays().toString())
    const hour = parseInt(duration.asHours().toString())
    const minute = parseInt(duration.asMinutes().toString())

    if (year > 0) {
      return `بیشتر از ${year} سال`
    }
    if (month > 0) {
      return `${month} ماه قبل`
    }
    if (day > 0) {
      return `${day} روز قبل`
    }
    if (hour > 0) {
      return `${hour} ساعت قبل`
    }
    if (minute > 0) {
      return `${minute} دقیقه قبل`
    }

    return `چند لحظه قبل`
  }
}
