import { FC, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom'
import { Button, Col, Form, Input, notification, Row, Typography } from 'antd'
import { strapi, useLogin } from "@/api";
import { useApp } from '@/hooks'
import { LoginImage, Logo } from '@/assets/images'

const Login: FC = () => {
  const { getUser } = useApp()
  const { mutateAsync: login, isLoading } = useLogin()
  const navigate = useNavigate()

  const handleLogin = (values: any) => {
    login(values)
      .then(() => {
        getUser()
        navigate('/panel')
        notification.success({
          message: 'خوش آمدید'
        })
      })
      .catch(() => {
        notification.error({
          message: 'نام کاربری یا رمز عبور اشتباه است'
        })
      })
  }

  return (
    <Row align="stretch" justify="center" className="text-center h-screen">
      <Col flex="400px" className="flex items-center justify-center flex-col px-10">
        <img src={Logo} className="w-20" />
        <Typography.Title level={2} className="my-4">
          ورود
        </Typography.Title>
        <Form onFinish={handleLogin} layout="vertical" className="mt-5 w-full">
          <Form.Item name="identifier" label="ایمیل">
            <Input className="dir-ltr" />
          </Form.Item>
          <Form.Item name="password" label="رمز عبور">
            <Input.Password />
          </Form.Item>

          <Button loading={isLoading} htmlType="submit" type="primary" size="large" block className="my-5">
            ورود
          </Button>
          <Typography>
            <span>هنوز ثبت نام نکردید؟</span>
            <Link to="/user/register">
              <Typography.Link className="mr-2">ثبت نام</Typography.Link>
            </Link>
          </Typography>
        </Form>
      </Col>
      <Col flex="auto" className="bg-gray-100 flex items-center justify-center">
        <LoginImage />
      </Col>
    </Row>
  )
}

export default Login
