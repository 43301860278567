import { FC } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Button, Col, Form, Input, notification, Row, Typography } from 'antd'
import { strapi } from '@/api'
import { useApp } from '@/hooks'
import { Logo, RegisterImage } from '@/assets/images'
import { RegisterParams } from '@/types/register'

const Register: FC = () => {
  const { getUser } = useApp()
  const navigate = useNavigate()

  const handleRegister = (values: RegisterParams) => {
    // @ts-ignore
    strapi.register({ ...values, username: values.email, mobile: `+98${values.mobile}` })
      .then(() => {
        getUser()
        navigate('/panel')
        notification.success({
          message: 'ثبت نام انجام شد، خوش آمدید.'
        })
      })
      .catch(() => {
        notification.error({
          message: 'مشکلی پیش آمده،‌ لطفا مجددا تلاش کنید.'
        })
      })
  }

  return (
    <Row align="stretch" justify="center" className="text-center h-screen">
      <Col flex="400px" className="flex items-center justify-center flex-col px-10">
        <img src={Logo} className="w-20" />
        <Typography.Title level={2} className="my-4">
          ثبت نام
        </Typography.Title>
        <Form onFinish={handleRegister} layout="vertical" className="mt-5 w-full">
          <Form.Item name="name" label="نام و نام خانوادگی">
            <Input />
          </Form.Item>
          <Form.Item
            name="mobile"
            label="شماره موبایل"
            rules={[{ pattern: /^\9[0-9]{9}/g, message: 'فرمت شماره موبایل اشتباه است.' }]}
          >
            <Input prefix="+98" />
          </Form.Item>
          <Form.Item name="email" label="ایمیل" rules={[{ required: true }]}>
            <Input className="dir-ltr" />
          </Form.Item>
          <Form.Item name="password" label="رمز عبور" rules={[{ required: true }]}>
            <Input.Password />
          </Form.Item>

          <Button htmlType="submit" type="primary" size="large" block className="my-5">
            ثبت نام
          </Button>
          <Typography>
            <span>قبلا ثبت نام کردید؟</span>
            <Link to="/user/login">
              <Typography.Link className="mr-2">ورود</Typography.Link>
            </Link>
          </Typography>
        </Form>
      </Col>
      <Col flex="auto" className="bg-gray-100 flex items-center justify-center">
        <RegisterImage />
      </Col>
    </Row>
  )
}

export default Register
