import { Outlet } from 'react-router-dom'
import { Layout } from 'antd'
import { useApp } from '@/hooks'
import Sidebar from './sidebar'

const PanelLayout = () => {
  const { sidebarCollapsed, toggleSidebar } = useApp()

  return (
    <Layout className="h-full">
      <Layout.Sider width={240} theme="light" collapsed={sidebarCollapsed} collapsible onCollapse={toggleSidebar}>
        <Sidebar />
      </Layout.Sider>
      <Layout>
        <Layout.Content>
          <Outlet />
        </Layout.Content>
      </Layout>
    </Layout>
  )
}

export default PanelLayout
