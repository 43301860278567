import Strapi from 'strapi-sdk-js';

export interface StrapiResponse<T> {
  data: T;
  meta: Record<string, unknown>;
}

export const strapi = new Strapi({
  url: "https://api.cheapserver.ir",
  prefix: "/api",
  store: {
    key: "cheapserver_auth",
    useLocalStorage: true,
  },
  axiosOptions: {},
})