import { FC } from 'react'
import { useLocation, matchPath } from 'react-router'
import { Link } from 'react-router-dom'
import { Avatar, Col, Menu, Row, Tooltip, Typography } from 'antd'
import { IconServer, IconLogout, IconWallet } from '@tabler/icons'
import { useApp } from '@/hooks'
import { Button } from '@/components'
import { Logo } from '@/assets/images'

const routes: any = [
  {
    path: '/panel',
    selectedKeys: ['dashboard'],
    activeKey: 'dashboard'
  },
  {
    path: 'panel/financial/*',
    activeKey: 'invoices',
    selectedKeys: ['invoices']
  }
]

const Sidebar: FC = () => {
  const { sidebarCollapsed, user, logout } = useApp()
  const { pathname } = useLocation()
  const activeRouteProps = routes.find((route: any) => matchPath(route.path, pathname))

  return (
    <Row align="stretch" className="flex-col h-full">
      <Row align="middle" justify="center" className="flex-col pt-8 pb-4">
        <img src={Logo} className={`mb-2 transition-all ${sidebarCollapsed ? 'w-10' : 'w-14'}`} />
        <Col>
          <Typography.Text
            className={`font-medium transition-all opacity-80 ${
              sidebarCollapsed ? 'hidden' : 'text-lg'
            }`}
          >
            Cheap Server
          </Typography.Text>
        </Col>
      </Row>
      <Row justify="space-between" className="flex-col flex-auto">
        <Col>
          <Menu
            theme="light"
            inlineCollapsed={sidebarCollapsed}
            mode="inline"
            {...activeRouteProps}
          >
            <Menu.Item icon={<IconServer />} className="font-medium" key="dashboard">
              <Link to="/panel">سرور ها</Link>
            </Menu.Item>
            {/*<Menu.Item icon={<IconWallet />} className="font-medium" key="invoices">
              <Link to="/panel/financial/transactions">مالی</Link>
            </Menu.Item>*/}
          </Menu>
        </Col>
        <Col>
          <Row
            className={`fixed w-[250px] bottom-12 py-4 px-5 ${sidebarCollapsed && 'justify-center flex-col'}`}
            gutter={[10, 10]}
            align="middle"
          >
            {/*{!sidebarCollapsed && (
              <Col span={24}>
                <div className="max-w-full px-3 py-4 bg-sky-100 rounded-xl mb-3">
                  <div className="flex justify-between">
                    <span>سرور:</span>
                    <span className="mr-2">{user.servicesCount}</span>
                  </div>
                  <div className="flex justify-between">
                    <span>اعتبار:</span>
                    <span className="mr-2">{user.creditString}</span>
                  </div>
                  <div className="mt-5">
                    <Button type="primary" icon={IconWallet}>
                      افزایش اعتبار
                    </Button>
                  </div>
                </div>
              </Col>
            )}*/}
            <Col>
              {sidebarCollapsed ? (
                <Tooltip title="مشاهده پروفایل" placement="left">
                  <Link to="/user/profile" className="inline-block">
                    <Avatar />
                  </Link>
                </Tooltip>
              ) : (
                <Link to="/user/profile" className="inline-block">
                  <Avatar />
                </Link>
              )}
            </Col>
            <Col flex="auto">
              <Row justify={sidebarCollapsed ? 'center' : 'space-between'}>
                {!sidebarCollapsed && (
                  <Col>
                    <Tooltip title="مشاهده پروفایل">
                      <Link to="/user/profile">
                        <Typography.Text className="font-medium">{user?.name}</Typography.Text>
                      </Link>
                    </Tooltip>
                  </Col>
                )}
                <Col>
                  <IconLogout
                    onClick={logout}
                    className="flex scale-reverse opacity-60 cursor-pointer hover:text-red-500 hover:opacity-80"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Row>
  )
}

export default Sidebar
