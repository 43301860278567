/* eslint-disable */
import 'nprogress/nprogress.css'

import NProgress from 'nprogress'
import axios from 'axios'

// @ts-ignore
const calculatePercentage = (loaded, total) => (Math.floor(loaded * 1.0) / total)

// @ts-ignore
export function loadProgressBar (config, instance = axios) {
  let requestsCounter = 0

  const setupStartProgress = () => {
    instance.interceptors.request.use(config => {
      requestsCounter++
      NProgress.start()
      return config
    })
  }

  const setupUpdateProgress = () => {
    // @ts-ignore
    const update = e => NProgress.inc(calculatePercentage(e.loaded, e.total))
    instance.defaults.onDownloadProgress = update
    instance.defaults.onUploadProgress = update
  }

  const setupStopProgress = () => {
    // @ts-ignore
    const responseFunc = response => {
      if ((--requestsCounter) === 0) {
        NProgress.done()
      }
      return response
    }

    // @ts-ignore
    const errorFunc = error => {
      if ((--requestsCounter) === 0) {
        NProgress.done()
      }
      return Promise.reject(error)
    }

    instance.interceptors.response.use(responseFunc, errorFunc)
  }

  NProgress.configure(config)
  setupStartProgress()
  setupUpdateProgress()
  setupStopProgress()
}