import { useMutation, useQuery } from "react-query";
import { strapi, StrapiResponse } from "@/api/strapi";
import { User } from "@/api/model/user";
import { Transaction } from "@/api/model/transaction";

export const useMe = () =>
  useMutation<StrapiResponse<User>>("services", () =>
    strapi.request("GET", "/users/me")
      .then((resp: any) =>
        Promise.resolve({
          data: new User(resp?.data),
          meta: resp?.meta
        })
      )
  );

export const useLogin = () =>
  useMutation<StrapiResponse<User>>("services", (data: any) =>
    strapi.login(data).then((resp: any) =>
      Promise.resolve({
        data: new User(resp.data),
        meta: resp.meta
      })
    )
  );

export const useTransactions = () =>
  useQuery<StrapiResponse<Transaction[]>>("services", () =>
    strapi.request("GET", "/transactions").then((resp: any) =>
      Promise.resolve({
        data: resp.data.map((t: any) => new Transaction(t)),
        meta: resp.meta
      })
    )
  );
