import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { ConfigProvider, notification } from 'antd'
import { QueryClient, QueryClientProvider } from 'react-query'
import { strapi } from '@/api'
import Routes from '@/routes'
import { AppProvider } from '@/hooks/providers'
import '@/assets/styles/globals.scss'
import antColors from '@/theme/colors'
import { loadProgressBar } from '@/common/axios-progress'

notification.config({
  placement: 'bottomLeft'
})

function App() {
  const queryClient = new QueryClient()
  const navigate = useNavigate()

  useEffect(() => {
    // @ts-ignore
    loadProgressBar({ parent: '#root' }, strapi.axios)
    strapi.axios.interceptors.response.use(undefined, (resp) => {
      if (resp.message.includes(403)) {
        navigate('/user/login')
      }
    })

    notification.config({
      placement: 'bottomLeft'
    })
    ConfigProvider.config({
      theme: antColors
    })
  }, [])

  return (
    <QueryClientProvider client={queryClient}>
      <AppProvider>
        <Routes />
      </AppProvider>
    </QueryClientProvider>
  )
}

export default App
