import { useState } from 'react'
import { useParams } from 'react-router'
import { Button, Card, Col, Descriptions, List, Row, Typography } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { PanelPageLayout } from '@/components'
import { useEnableRescue, usePowerService, useService } from '@/api'
import RebuildComponent from '@/pages/service/components/rebuild'
import IPComponent from '@/pages/service/components/ip'

export function Service() {
  const [rescue, setRescue] = useState<any>()
  const [tab, setTab] = useState<'rescue' | 'rebuild' | 'ip'>('rebuild')
  const { id } = useParams<{ id: string }>()
  const { data: service } = useService(id)
  const { mutateAsync: enableRescue } = useEnableRescue()
  const { mutate: powerService } = usePowerService()

  function handlePower() {
    if (!service?.data.hetzner?.status) return
    powerService({
      service: parseInt(id as string),
      action: service?.data.hetzner?.status === 'running' ? 'poweroff' : 'poweron'
    })
  }

  function handleEnableRescue() {
    enableRescue({ service: parseInt(id as string) }).then((resp) => {
      setRescue(resp)
    })
  }

  if (!service?.data) {
    return <></>
  }

  return (
    <PanelPageLayout
      title={`سرور ${service?.data.label || ''}`}
      extra={
        <div>
          {service?.data.hetzner?.status === 'running' && (
            <Button type="primary" danger onClick={handlePower}>
              خاموش کردن
            </Button>
          )}
          {service?.data.hetzner?.status === 'off' && (
            <Button type="primary" onClick={handlePower}>
              روشن کردن
            </Button>
          )}
          {['starting', 'stopping'].includes(service?.data.hetzner?.status || '') && (
            <div className="py-2 px-5 bg-green-500 rounded-lg flex items-center !text-white">
              <LoadingOutlined style={{ fontSize: 20 }} spin />
            </div>
          )}
        </div>
      }
    >
      <div className="mb-10">
        <Card className="">
          {service?.data && (
            <Descriptions bordered>
              <Descriptions.Item label="آیپی">
                <Typography.Paragraph className="flex !m-0" copyable>
                  {service?.data.hetzner?.public_net.ipv4?.ip || '-'}
                </Typography.Paragraph>
              </Descriptions.Item>
              <Descriptions.Item label="پردازنده">{service?.data.server?.cpu}</Descriptions.Item>
              <Descriptions.Item label="رم">{service?.data.server?.ram}</Descriptions.Item>
              <Descriptions.Item label="حافظه">{service?.data.server?.hard}</Descriptions.Item>
              <Descriptions.Item label="لوکیشن">
                {service?.data.hetzner?.datacenter?.location?.city}
              </Descriptions.Item>
            </Descriptions>
          )}
        </Card>
      </div>
      <Row gutter={[15, 15]}>
        <Col span={6}>
          <List className="rounded bg-white p-3">
            <List.Item
              onClick={() => setTab('rebuild')}
              className={`cursor-pointer hover:bg-gray-100 px-2 rounded ${
                tab === 'rebuild' && '!bg-blue-400 text-white'
              }`}
            >
              Rebuild
            </List.Item>
            <List.Item
              onClick={() => setTab('ip')}
              className={`cursor-pointer hover:bg-gray-100 px-2 rounded ${
                tab === 'ip' && '!bg-blue-400 text-white'
              }`}
            >
              Primary IP
            </List.Item>
            <List.Item
              onClick={() => setTab('rescue')}
              className={`cursor-pointer hover:bg-gray-100 px-2 rounded ${
                tab === 'rescue' && '!bg-blue-400 text-white'
              }`}
            >
              فعال سازی Rescue
            </List.Item>
            <List.Item className="cursor-pointer hover:bg-gray-100 px-2 rounded">به زودی</List.Item>
            <List.Item className="cursor-pointer hover:bg-gray-100 px-2 rounded">به زودی</List.Item>
          </List>
        </Col>
        <Col span={18}>
          {tab === 'rescue' && (
            <Card className="">
              <p>
                سیستم نجات یک محیط مبتنی بر شبکه است و می تواند برای رفع مشکلاتی که از بوت معمولی
                جلوگیری می کند استفاده شود. نصب توزیع های لینوکس سفارشی که مستقیماً توسط ما ارائه
                نشده اند نیز مفید است. شما می توانید هارد دیسک سرور را در داخل سیستم نجات سوار کنید.
                پس از فعال کردن سیستم نجات، باید سرور را در 60 دقیقه آینده راه اندازی مجدد کنید تا
                آن را فعال کنید. پس از راه اندازی مجدد مجدد سرور شما دوباره از دیسک محلی خود بوت می
                شود.
              </p>
              {rescue?.status !== 'success' && (
                <Button type="primary" size="large" onClick={handleEnableRescue}>
                  فعال سازی
                </Button>
              )}
              {rescue?.status === 'success' && (
                <div
                  className="p-4 border border-red-400 border-l-2 border-solid rounded"
                  style={{ direction: 'ltr' }}
                >
                  <div>User: root</div>
                  <div>
                    Password: <span>{rescue.response.root_password}</span>
                  </div>
                </div>
              )}
            </Card>
          )}
          {tab === 'rebuild' && <RebuildComponent service={service?.data} />}
          {tab === 'ip' && <IPComponent service={service?.data} />}
        </Col>
      </Row>
    </PanelPageLayout>
  )
}
