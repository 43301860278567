export interface Transaction {
  id: number
  credit: number
  status: 'success' | 'failed'
  type: 'add' | 'reduce'
  referenceId: string
  createdAt: string
}

export class Transaction {
  creditString = "0 تومان"

  constructor(transaction: Transaction) {
    this.id = transaction?.id
    this.credit = transaction?.credit
    this.creditString = `${new Intl.NumberFormat('fa-IR', { maximumSignificantDigits: 3 }).format(transaction?.credit)}${transaction.type === 'add' ? '' : '-'} تومان`
    this.status = transaction?.status
    this.type = transaction?.type
    this.referenceId = transaction?.referenceId
    this.createdAt = transaction?.createdAt
  }
}