import { FC } from 'react'
import { Button, Col, Row, Table } from 'antd'
import { IconShoppingCartPlus } from '@tabler/icons'
import { PanelPageLayout } from '@/components'
import { Server as ServerType, useServers } from '@/api'
import { serverColumns } from './server.utils'

const Server: FC = () => {
  const { data: servers } = useServers()

  const actionCell = {
    title: '',
    width: 100,
    render: (server: ServerType) => (
      <Button
        className={`${server.featured && 'bg-green-500 border-green-600'}`}
        type="primary"
        icon={<IconShoppingCartPlus className="inline-block align-middle ml-2" />}
      >
        سفارش
      </Button>
    )
  }

  return (
    <PanelPageLayout title="لیست سرور">
      <Row>
        <Col span={24}>
          <Table
            rowClassName={(server) => (server.featured ? 'featured bg-green-100 hover:bg-green-200' : '')}
            dataSource={servers?.data}
            columns={[actionCell, ...serverColumns]}
            pagination={false}
          />
        </Col>
      </Row>
    </PanelPageLayout>
  )
}

export default Server
