import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Col, Dropdown, List as AntList, MenuProps, Row, Typography } from 'antd'
import moment from 'jalali-moment'
import { LoadingOutlined } from '@ant-design/icons'
import { IconDotsVertical } from '@tabler/icons'
import { useApp } from '@/hooks'
import { List, ListColumn, PanelPageLayout } from '@/components'
import { hetznerStatus, Service, usePowerService, useServices } from '@/api'

const columns: ListColumn<Service>[] = [
  {
    title: '',
    dataIndex: 'id',
    width: 50,
    render: (item: Service) => {
      if (item.hetzner?.status === 'starting' || item.hetzner?.status === 'stopping') {
        return <LoadingOutlined />
      }

      return item.hetzner?.status === 'running' ? (
        <span className="flex h-3 w-3 relative ml-4">
          <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-75"></span>
          <span className="relative inline-flex rounded-full h-3 w-3 bg-green-500"></span>
        </span>
      ) : (
        <span className="flex h-3 w-3 relative ml-4">
          <span className="relative inline-flex rounded-full h-3 w-3 bg-gray-500"></span>
        </span>
      )
    }
  },
  {
    title: 'نام',
    dataIndex: 'label',
    width: 'auto',
    render: (item: Service) => (
      <Link to={`/panel/services/${item.id}`}>
        <AntList.Item.Meta
          title={item.label}
          description={`${item.server.name} / ${item.server.hard}`}
        />
      </Link>
    )
  },
  {
    title: 'آیپی',
    dataIndex: 'ip',
    width: 150,
    render: (item: Service) => (
      <Typography.Title copyable level={5} className="m-0">
        {item.hetzner?.public_net?.ipv4?.ip}
      </Typography.Title>
    )
  },
  {
    title: 'لوکیشن',
    dataIndex: 'location',
    width: 150,
    render: (item: Service) => (
      <Typography.Title level={5} className="m-0">
        {item.hetzner?.datacenter.location.city}
      </Typography.Title>
    )
  },
  {
    title: 'تاریخ ایجاد',
    dataIndex: 'createdAt',
    width: 150,
    render: (item: Service) => (
      <Typography.Title level={5} className="m-0">
        {moment(item.hetzner?.created).locale('fa').format('jDD jMMMM jYYYY')}
      </Typography.Title>
    )
  }
]

const Dashboard = () => {
  const { user } = useApp()
  const { data: services, refetch } = useServices()
  const { mutate: powerService } = usePowerService()

  function handlePower(service: number, status?: hetznerStatus) {
    if (!status) return
    powerService({ service, action: status === 'running' ? 'poweroff' : 'poweron' })
  }

  useEffect(() => {
    if (services?.data) {
      const loading = services?.data.find(
        (s) => s.hetzner?.status === 'starting' || s.hetzner?.status === 'stopping'
      )

      if (loading) {
        setTimeout(() => {
          refetch()
        }, 2000)
      }
    }
  }, [services])

  return (
    <PanelPageLayout
      title="داشبورد"
      /*extra={
        <Link to="/panel/add-server">
          <Button type="primary" icon={IconPlus}>افزودن سرور</Button>
        </Link>
      }*/
    >
      <Row gutter={[15, 15]}>
        <Col span={24}>
          <List<Service>
            data={services?.data || []}
            columns={[
              ...columns,
              {
                title: '',
                dataIndex: 'hetzner',
                width: 20,
                render: (item: Service) => {
                  const items: MenuProps['items'] = [
                    {
                      label: (
                        <div>{item.hetzner?.status === 'running' ? 'خاموش کردن' : 'روشن کردن'}</div>
                      ),
                      onClick: () => handlePower(item.id, item.hetzner?.status),
                      danger: item.hetzner?.status === 'running',
                      key: '0'
                    }
                  ]

                  return (
                    <Dropdown menu={{ items }} trigger={['click']}>
                      <IconDotsVertical className="p-1 cursor-pointer rounded-full hover:bg-gray-100" />
                    </Dropdown>
                  )
                }
              }
            ]}
          />
        </Col>
      </Row>
    </PanelPageLayout>
  )
}
export default Dashboard
