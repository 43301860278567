import { useState } from 'react'
import { Button, Card, Select, Typography } from 'antd';
import { Service, useImages, useRebuild } from '@/api'

interface Props {
  service?: Service
}

export default function RebuildComponent({ service }: Props) {
  const [os, setOs] = useState<string>()
  const { data: images } = useImages(service?.id.toString())
  const { data: rebuild, mutate, isLoading } = useRebuild()

  const handleRebuild = () => {
    if (!service || !os) return
    mutate({
      id: service.id,
      data: {
        image: os
      }
    })
  }

  // eslint-disable-next-line no-console
  console.log(rebuild);

  return (
    <Card>
      <div className="flex gap-4">
        <Select
          showSearch
          optionFilterProp="children"
          className="flex-1"
          placeholder="سیستم عامل را انتخاب کنید"
          value={os}
          onChange={(value) => setOs(value)}
        >
          {images?.response?.images?.map((image: any) => (
            <Select.Option key={image.id} value={image.id}>
              {image.description}
            </Select.Option>
          ))}
        </Select>
        <Button loading={isLoading} disabled={!os} type="primary" onClick={handleRebuild}>
          Rebuild
        </Button>
      </div>
      <div className="mt-5">
        {!!rebuild && <div className="flex gap-4">
          <div>رمز عبور جدید:</div>
          <Typography.Paragraph copyable>{rebuild?.response?.root_password}</Typography.Paragraph>
        </div>}
      </div>
    </Card>
  )
}
