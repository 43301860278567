import { ReactElement } from 'react'
import { Col, Row, List as AntList } from 'antd'

interface ListItem {
  id: number

  [k: string]: any
}

export interface ListColumn<DataType> {
  title: string
  dataIndex: keyof DataType
  className?: string
  render?: (item: DataType) => JSX.Element
  width: number | 'auto'
}

interface ListProps<DataType> {
  data: DataType[]
  columns: ListColumn<DataType>[]
}

type ListType = <DataType extends ListItem>(
  props: ListProps<DataType>
) => ReactElement<ListProps<DataType>>

const { Item } = AntList

export const List: ListType = ({ data, columns }) => (
  <>
    <Row align="middle" justify="space-between" className="px-6 py-3">
      {columns.map((column) => (
        <Col
          key={column.dataIndex.toString()}
          flex={column.width === 'auto' ? 'auto' : `${column.width}px`}
        >
          {column.title}
        </Col>
      ))}
    </Row>
    <AntList bordered>
      {data.map((item) => (
        <Item key={item.id} className="bg-white">
          {columns.map((column) => (
            <Col
              key={`${item.id}-${column.dataIndex}`}
              flex={column.width === 'auto' ? 'auto' : `${column.width}px`}
              className={`p-0 ${column.className || ''}`}
            >
              {column.render ? column.render(item) : item[column.dataIndex]}
            </Col>
          ))}
        </Item>
      ))}
    </AntList>
  </>
)
