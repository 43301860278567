import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { strapi, User } from '@/api'
import { AppContext, AppCtx } from './providers'

interface useAppMethods extends AppContext {
  toggleSidebar: () => void
  logout: () => void
}

export const useApp: () => useAppMethods = () => {
  const navigate = useNavigate()
  const ctx = useContext(AppCtx)
  const { setState, setUser } = ctx

  const toggleSidebar = () => {
    setState((prev: any) => ({ ...prev, sidebarCollapsed: !prev.sidebarCollapsed }))
  }

  const logout = () => {
    strapi.logout()
    setUser(new User())

    navigate('/user/login')
  }

  return {
    ...ctx,
    toggleSidebar,
    logout
  }
}
