import { Tabs } from 'antd'
import { useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'
import { PanelPageLayout } from '@/components'
import { useApp } from '@/hooks'
import { useTransactions } from '@/api'
import { Transactions } from './components'

const Financial = () => {
  const { user } = useApp()
  const { data: transactions } = useTransactions()
  const { tab } = useParams()
  const navigate = useNavigate()

  return (
    <PanelPageLayout title="مالی">
      <Tabs
        centered
        type="card"
        activeKey={tab}
        onChange={(tab) => navigate(`/panel/financial/${tab}`)}
      >
        <Tabs.TabPane tab="پیش فاکتور" key="pre-invoice">
          <div className="p-3 bg-white rounded-lg">test</div>
        </Tabs.TabPane>
        <Tabs.TabPane tab="فاکتور ها" key="invoices">
          <div className="p-3 bg-white rounded-lg">test</div>
        </Tabs.TabPane>
        <Tabs.TabPane tab="تراکنش ها" key="transactions">
          <div className="p-3 bg-white rounded-lg">
            <Transactions data={transactions?.data || []} />
          </div>
        </Tabs.TabPane>
      </Tabs>
    </PanelPageLayout>
  )
}

export default Financial
