import { useState } from 'react'
import axios from 'axios'
import { Button, Card, Divider, Select, Table } from 'antd'
import { Service, useCreatePrimaryIp, useDeletePrimaryIp, useIpAction, usePrimaryIps } from '@/api'
import IpCheckResult from '@/pages/service/components/ipCheckResult'

interface Props {
  service?: Service
}

export default function IPComponent({ service }: Props) {
  const [ip, setIp] = useState<any>()
  const { data: ips } = usePrimaryIps(service?.id.toString())
  const { mutate, isLoading } = useIpAction()
  const { mutate: createIp, isLoading: createLoading } = useCreatePrimaryIp()
  const { mutate: deleteIp } = useDeletePrimaryIp()
  const [checkRequest, setCheckRequest] = useState<string>()
  const [nodes, setNodes] = useState<any>({})

  const handleAssign = (newIp = ip) => {
    if (!service || !newIp) return
    mutate({
      service: service.id,
      ipId: newIp?.id,
      action: 'assign',
      data: {
        assignee_id: service.hetzner?.id,
        assignee_type: 'server'
      }
    })
  }

  const handleUnassign = () => {
    if (!service) return
    mutate({
      service: service.id,
      ipId: service?.hetzner?.public_net.ipv4?.id,
      action: 'unassign',
      data: {}
    })
  }

  const handleCreateIp = () => {
    if (!service) return
    createIp({
      service: service.id,
      data: {
        assignee_type: 'server',
        auto_delete: false,
        datacenter: service.hetzner?.datacenter.name,
        type: 'ipv4',
        name: Date.now().toString()
      }
    })
  }

  const handleDeleteIp = (ip: any) => {
    if (!service || !ip) return
    deleteIp({
      service: service.id,
      ipId: ip?.id
    })
  }

  const testIp = () => {
    axios
      .get(`https://check-host.net/check-http?host=${service?.hetzner?.public_net.ipv4?.ip}`, {
        headers: {
          Accept: 'application/json'
        }
      })
      .then((response) => {
        setNodes(response?.data?.nodes);
        setCheckRequest(response?.data?.request_id);
      })
  }

  const ipColumns = [
    {
      title: 'IP',
      dataIndex: 'ip'
    },
    {
      title: 'Location',
      dataIndex: ['datacenter', 'location', 'city']
    },
    {
      title: 'Assigned',
      dataIndex: 'assignee_id',
      render: (assignee_id: number) =>
        assignee_id ? (
          <div className="text-blue-500">متصل شده به سرور</div>
        ) : (
          <div className="text-red-500">متصل نشده</div>
        )
    },
    {
      title: 'Action',
      render: (ip: any) => (
        <div>
          {ip.assignee_id ? (
            <Button className="w-32" danger onClick={handleUnassign} loading={isLoading}>
              جداسازی از سرور
            </Button>
          ) : (
            <>
              <Button
                className="w-32"
                ghost
                type="primary"
                onClick={() => handleAssign(ip)}
                loading={isLoading}
              >
                اتصال به سرور
              </Button>
              <Button
                danger
                className="mr-2"
                type="primary"
                onClick={() => handleDeleteIp(ip)}
                loading={isLoading}
              >
                حذف آیپی
              </Button>
            </>
          )}
        </div>
      )
    }
  ]

  return (
    <Card>
      <div className="flex justify-between">
        <Button
          disabled={!service?.hetzner?.public_net.ipv4}
          onClick={handleUnassign}
          danger
          loading={isLoading}
        >
          جداسازی آیپی از سرور
        </Button>
        <Button onClick={handleCreateIp} type="primary" loading={createLoading}>
          ایجاد آیپی جدید
        </Button>
      </div>
      <Divider />
      <div>
        <div className="flex gap-5 items-center">
          <div>آیپی: {service?.hetzner?.public_net.ipv4?.ip || '-'}</div>
          <div>
            <Button type="primary" onClick={testIp}>
              تست آیپی
            </Button>
          </div>
        </div>
      </div>
      <div>
        <IpCheckResult requestId={checkRequest} nodes={nodes} />
      </div>
      <Divider />
      <div>
        <Select
          showSearch
          optionFilterProp="children"
          className="flex-1"
          placeholder="آیپی را انتخاب کنید"
          value={ip}
          onChange={(value) => setIp(value)}
        >
          {ips?.response?.primary_ips
            ?.filter((ip: any) => !ip.assignee_id)
            ?.filter(
              (ip: any) => ip.datacenter.location.id === service?.hetzner?.datacenter.location?.id
            )
            .map((ip: any) => (
              <Select.Option key={ip.id} value={ip.id}>
                {ip.ip}
              </Select.Option>
            ))}
        </Select>
        <Button
          loading={isLoading}
          type="primary"
          onClick={handleAssign}
          className="!mx-2"
          disabled={!ip || !!service?.hetzner?.public_net.ipv4}
        >
          اتصال آیپی به سرور
        </Button>
      </div>
      <Divider />
      <div>
        <Table
          dataSource={
            ips?.response?.primary_ips?.filter(
              (ip: any) => !ip.assignee_id || ip.assignee_id === service?.hetzner?.id
            ) || []
          }
          columns={ipColumns}
        />
      </div>
    </Card>
  )
}
