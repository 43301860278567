import { useQuery } from 'react-query'
import { strapi, StrapiResponse } from '@/api'
import { Server } from '@/api/model/server'

export const useServers = () => ({
  ...useQuery<StrapiResponse<Server[]>>('servers', () =>
    strapi.request('GET', '/servers').then((resp: any) => {
      const response: Server[] = resp.data.map((item: any) => new Server({
        id: item.id,
        ...item.attributes
      }))
      return Promise.resolve({
        data: response,
        meta: resp.meta
      })
    })
  ),
  queryKey: 'servers'
})
