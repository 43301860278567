import { createContext, Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { strapi, StrapiUser, useMe, User } from "@/api";
import usePersistedState from "@/hooks/usePersistedState";

interface AppState {
  sidebarCollapsed: boolean;
}

export interface AppContext extends AppState {
  setState: Dispatch<SetStateAction<AppState>>;
  setUser: Dispatch<SetStateAction<User>>;
  user: User;

  getUser: () => void;
}

export const AppCtx = createContext<AppContext>({} as AppContext);

export const AppProvider: FC = ({ children }) => {
  const navigate = useNavigate();
  const [state, setState] = useState<AppState>({
    sidebarCollapsed: false
  });
  const [user, setUser] = useState<User>({ init: true } as User);
  const { mutateAsync: getMe, isLoading } = useMe();

  const getUser = () => {
    if (localStorage.getItem('cheapserver_auth')) {
      getMe().then((resp) => {
        setUser(resp?.data);
        strapi.setUser(resp?.data as StrapiUser);
      }).catch(e => {
        // eslint-disable-next-line no-console
        console.log(e);
      });
    } else {
      setUser(new User());
    }
  };

  strapi.axios.interceptors.response.use(undefined, (error) => {
    if (error.status === 401) {
      strapi.logout();
      return navigate("/user/login");
    }

    return Promise.reject(error);
  });

  useEffect(() => {
    getUser();
  }, []);

  if (isLoading || user.init) return <></>;

  return (
    <AppCtx.Provider
      value={{
        ...state,
        setState,
        user,
        getUser,
        setUser
      }}
    >
      {children}
    </AppCtx.Provider>
  );
};
