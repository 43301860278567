export const serverColumns = [
  {
    title: 'قیمت ماهیانه',
    className: "text-center",
    dataIndex: 'priceString',
  },
  {
    title: 'نام سرور',
    className: "text-center",
    dataIndex: 'name'
  },
  {
    title: 'پردازنده',
    className: 'dir-ltr text-center',
    dataIndex: 'cpu'
  },
  {
    title: 'رم',
    className: 'dir-ltr text-center',
    dataIndex: 'ram'
  },
  {
    title: 'هارد',
    className: 'dir-ltr text-center',
    dataIndex: 'hard'
  },
  {
    title: 'ترافیک',
    className: 'dir-ltr text-center',
    dataIndex: 'traffic'
  }
]
