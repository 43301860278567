import { useEffect, useMemo, useState } from 'react'
import axios from 'axios'
import { Table } from 'antd'

const IpCheckResult = ({ requestId, nodes }: { requestId?: string; nodes?: any }) => {
  const [done, setDone] = useState(false)
  const [result, setResult] = useState<any>({})

  const checkResult = () => {
    axios
      .get(`https://check-host.net/check-result/${requestId}`, {
        headers: {
          Accept: 'application/json'
        }
      })
      .then((response) => {
        setResult(response.data)
        if (Object.values(response.data).filter((i) => i === null)?.length > 0) {
          setTimeout(() => {
            checkResult()
          }, 500)
        } else {
          setDone(true)
        }
      })
  }

  useEffect(() => {
    if (requestId) {
      setDone(false)
      checkResult()
    }
  }, [requestId])

  const data = useMemo(() => {
    const dataset: any = []
    Object.keys(result)?.map((key) => {
      const item = result[key]?.[0]
      const node = nodes[key]
      const isIran = node[1].includes('Iran')

      if (!item) return

      dataset.push({
        location: `${node[1]} - ${node[2]}`,
        status: item[0] === 0 ? 'Error' : 'OK',
        time: parseFloat(item[1]).toFixed(3),
        result: item[2]
      })
      return (
        <div
          key={key}
          className={`flex gap-2 ${isIran ? 'text-green-500' : ''}`}
          style={{ direction: 'ltr' }}
        >
          <div className="w-48">
            {node[1]} - {node[2]}
          </div>
          <div>{item[0] === 0 ? 'Error' : 'OK'}</div>
          <div>{parseFloat(item[1]).toFixed(3)} s</div>
          <div>{item[2]}</div>
        </div>
      )
    })

    return dataset
  }, [result, nodes])

  return (
    <div>
      {done ? (
        <Table
          className="mt-4"
          scroll={{y: '700px'}}
          bordered
          dataSource={data}
          size="small"
          pagination={false}
          rowClassName={(record) => (record.location.includes('Iran') ? 'bg-green-300' : '')}
          columns={[
            {
              title: 'لوکیشن',
              dataIndex: 'location'
            },
            {
              title: 'وضعیت',
              dataIndex: 'status'
            },
            {
              title: 'زمان',
              dataIndex: 'time'
            },
            {
              title: 'نتیجه',
              dataIndex: 'result'
            }
          ]}
        />
      ) : (
        requestId && <div>Loading...</div>
      )}
    </div>
  )
}

export default IpCheckResult
